var columns = [{
  title: '商品ID',
  dataIndex: 'productid',
  key: 'productid',
  // width: '7%',
  scopedSlots: {
    customRender: 'productid'
  }
}, {
  title: '商品类目',
  dataIndex: 'categoryname',
  key: 'categoryname',
  width: '30%',
  scopedSlots: {
    customRender: 'categoryname'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  // width: '7%',
  scopedSlots: {
    customRender: 'brandname'
  }
},
// {
//     title: '系列名称',
//     dataIndex: 'seriename',
//     key: 'seriename',
//     // width: '9%',
//     scopedSlots: {
//         customRender: 'seriename'
//     }
// },
{
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '15%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurl',
  key: 'imgurl',
  // width: '9%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  // width: '8%',
  scopedSlots: {
    customRender: 'operation'
  }
}];
var addcolumns = [{
  title: '商品ID',
  dataIndex: 'id',
  key: 'id',
  // width: '7%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '商品类目',
  dataIndex: 'parentcategoryname',
  key: 'parentcategoryname',
  // width: '11%',
  scopedSlots: {
    customRender: 'parentcategoryname'
  }
}, {
  title: '品牌名称',
  dataIndex: 'brandname',
  key: 'brandname',
  // width: '7%',
  scopedSlots: {
    customRender: 'brandname'
  }
},
// {
//     title: '系列名称',
//     dataIndex: 'seriename',
//     key: 'seriename',
//     // width: '9%',
//     scopedSlots: {
//         customRender: 'seriename'
//     }
// },
{
  title: '货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '15%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  // width: '9%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  key: 'operation',
  // width: '8%',
  scopedSlots: {
    customRender: 'operation'
  }
}];
export { columns, addcolumns };