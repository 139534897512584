var searchKeys = [{
  key: "sku",
  label: "货号",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "productid",
  label: "商品ID",
  placeholder: "请输入",
  required: false,
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
var addsearchKeys = [{
  key: "productid",
  label: "商品ID",
  placeholder: "多个商品ID间以英文逗号隔开",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys, addsearchKeys };